import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import Logo from "./images/Logo4.png";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router-dom";
import axios from "axios";
import PayButton from "./PayButton";

// async function handleToken(token, total) {
// }

// async function onPay(e){
// }

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    borderRadius: 10,
    // width: 500,
    // height:280,
    width: "38em",
    height: "25em",
    // backgroundColor:"#4A90E9",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 15,
  },
  pos: {
    marginBottom: 12,
  },
  card: {
    alignContent: "center",
    justify: "center",
    width: "90%",
    height: "93%",
    // overflowY: screenMD ? "auto" : "hidden",
    overflow: "hidden",
    borderRadius: "20px",
  },
  back: {
    //green
    // background: "linear-gradient(to right, #2AC4A3 40%, #16A5A9 60%)",
    //blue
    background:
      "linear-gradient(108.26585207970061deg, rgba(1, 96, 186,1) 5.894948755490484%,rgba(0, 0, 0,1) 95.28001464128842%)",
    //yellow
    // background: "linear-gradient(315deg, #fbb034 0%, #ffdd00 74%)",
    //  background:"linear-gradient(to right, rgba(22,163,170,0.5), rgba(22,163,170,1))",
    height: "100vh",
    // height:"",
    // width:"100%",
  },
});

export default function OutlinedCard(props) {
  const classes = useStyles();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    if (localStorage.getItem("is_success")) {
      localStorage.removeItem("is_success");
    }
  }, []);

  const handleToken = async (token, total) => {
    // const history = useHistory();
    console.log("Reached");
    let new_data = { inv_id: localStorage.getItem("invoice_id") };
    await axios
      .post(
        process.env.REACT_APP_API_URL + "oa/api/trial/initiateTransaction",
        new_data
      )
      .then(async (response) => {
        console.log(response);
        localStorage.setItem("trans_id", response.data.trans_id);
        // localStorage.setItem("is_success", true);
        // history.push("/payment_secc");
        // window.location.href='/payment_secc'
      })
      .catch((error) => {
        console.log("Mone", error.response.data.message);
      });
    var all_total = total_amount;
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "oa/api/trial/verifyStripe",
      { token, all_total }
    );
    const { status } = response.data;
    console.log("Response:", response.data);
    if (status === "success") {
      console.log("Success! Check email for details");
      let new_data = {
        trans_id: localStorage.getItem("trans_id"),
        status: "1",
        inv_id: localStorage.getItem("invoice_id"),
        trial_id: localStorage.getItem("trial_id"),
      };
      axios
        .post(
          process.env.REACT_APP_API_URL + "oa/api/trial/completeTransaction",
          new_data
        )
        .then((response) => {
          console.log(response);
          localStorage.setItem("is_success", true);
          history.push("/payment_secc");
          // window.location.href='/payment_secc'
        })
        .catch((error) => {
          console.log("MonO", error.response.data.message);
        });
    } else {
      console.log("Something went wrong");
      let new_data = {
        trans_id: localStorage.getItem("trans_id"),
        status: "-1",
        inv_id: localStorage.getItem("invoice_id"),
        trial_id: localStorage.getItem("trial_id"),
      };
      axios
        .post(
          process.env.REACT_APP_API_URL + "oa/api/trial/completeTransaction",
          new_data
        )
        .then((response) => {
          console.log(response);
          localStorage.setItem("is_success", false);
          history.push("/payment_secc");
          // window.location.href='/payment_secc'
        })
        .catch((error) => {
          console.log("MonO", error.response.data.message);
        });
    }
  };

  const onPay = async (e) => {
    e.preventDefault();
    let new_data = { inv_id: localStorage.getItem("invoice_id") };
    await axios
      .post(
        process.env.REACT_APP_API_URL + "oa/api/trial/initiateTransaction",
        new_data
      )
      .then(async (response) => {
        console.log(response);
        localStorage.setItem("trans_id", response.data.trans_id);
        // localStorage.setItem("is_success", true);
        // history.push("/payment_secc");
        // window.location.href='/payment_secc'
      })
      .catch((error) => {
        console.log("Mone", error.response.data.message);
      });
    const payment_amount = total_amount;
    console.log('p',(payment_amount * 100).toFixed(2));
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "oa/api/trial/createRazorpay",
      { payment_amount: payment_amount }
    );
    const { order_id } = response.data;
    console.log("NUI", order_id);
    const self = this;
    const options = {
      key: "rzp_live_yGmSb5dlxdzTiG",
      amount: (payment_amount * 100).toFixed(2),
      name: "UC-School",
      description: "Payment for UC-School Registration",
      order_id: order_id,
      handler(response) {
        console.log("Boi", response);
        let new_data = {
          trans_id: localStorage.getItem("trans_id"),
          status: "1",
          inv_id: localStorage.getItem("invoice_id"),
          trial_id: localStorage.getItem("trial_id"),
        };
        axios
          .post(
            process.env.REACT_APP_API_URL + "oa/api/trial/completeTransaction",
            new_data
          )
          .then((response) => {
            console.log(response);
            localStorage.setItem("is_success", true);
            history.push("/payment_secc");
            // window.location.href='/payment_secc'
          })
          .catch((error) => {
            console.log("Mone", error.response.data.message);
          });
      },

      prefill: {
        name: "",
        email: "",
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#013f7a",
      },
    };
    const rzp1 = new window.Razorpay(options);

    rzp1.open();
  };

  const total_amount = localStorage.getItem("total_price");
  const history = useHistory();
  // const total_amount = props.tot_price
  const [total, setTotal] = useState(total_amount ? total_amount : 0);
  var curr_symbol = "$";

  if (total_amount) {
    const country = localStorage.getItem("country");
    var is_razor = false;
    var pay_handler = handleToken;
    if (country == "India") {
      curr_symbol = "₹";
      // setSymbol("₹");
      is_razor = true;
      pay_handler = onPay;
    }
    // console.log('moo',total_price)
  } else {
    history.push("/");
  }

  return (
    <Grid
      container
      //  direction="row"
      className={classes.back}
      alignItems="center"
      justify="center"
    >
      <Grid container justify="center" alignItems="center">
        <Card
          className={classes.root}
          // style={{ position: "relative" }}
          variant="outlined"
          style={{
            overflowY: "auto",
            height: "20%",
            overflowX: "hidden",
          }}
        >
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ marginTop: "0.5em" }}
          >
            <Grid item>
              <img
                src={Logo}
                alt="logo"
                style={{
                  width: "17em",
                  alignItems: "center",
                  // backgroundColor: "#244f50",
                  borderRadius: 10,
                  // position: "absolute",
                  // bottom: 10,
                  // // right:830,
                  // left: 10,
                }}
              ></img>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item container>
              <CardContent
                style={{
                  // backgroundColor:"#4A90E9",
                  color: "#013F7A",
                  marginTop: "1em",
                }}
              >
                <Grid item>
                  <Typography
                    style={{ fontSize: 18, fontWeight: "bold" }}
                    variant="subtitle1"
                  >
                    {" "}
                    Your transaction has been successfully initiated. Please
                    click here to proceed to make payment.
                  </Typography>
                  <Divider light />
                </Grid>
                <Grid
                  item
                  // style={{marginTop:"1em"}}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ fontSize: 18, fontWeight: "" }}
                  >
                    {" "}
                    With Click to Pay Now, you can now use NetBanking/ Debit
                    Card/ Credit Card to make the payment.
                  </Typography>
                  <Divider light />
                </Grid>
                <Grid
                  item
                  // style={{marginTop:"1em"}}
                >
                  <Typography
                    style={{ fontSize: 18, fontWeight: "" }}
                    variant="subtitle1"
                  >
                    {" "}
                    Please select the mode and enter further details to complete
                    the payment.
                  </Typography>
                  <Divider light />
                </Grid>
              </CardContent>
            </Grid>
          </Grid>
          <CardActions>
            <Grid
              container
              justify="center"
              //   style={{marginTop:"2em"#4A90E9}}
            >
              {/* <PayButton is_razor={true} curr_symbol={curr_symbol} total={total} onPay={onPay}/> */}
              <PayButton
                is_razor={is_razor}
                curr_symbol={curr_symbol}
                total={total_amount}
                onPay={pay_handler}
              />
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
